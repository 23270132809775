const bloglist = [
    {
      id:"1",
      title: "Revolutionizing Real Estate Development Financing with NFT-Backed Loans",
      cover: require("../assets/images//blog/1/real_estate.jpg"),
      subtitle:"See NFT",
      description:"The \n\ real estate industry has been evolving at a rapid pace..."
    },
    
    
  ];
  export  default bloglist